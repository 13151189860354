<template>
    <div class="visit-page pb-3">
        <div class="container">
            <TheEventList
                v-for="(item, id) in education"
                :education="item"
                :education-key="id"
                :key="id"
            />
        </div>
    </div>
</template>

<script>
    import { get } from '@/lib/axios';
    import TheEventList from '@/components/education/TheEventList';

    export default {
        name: 'Education',
        components: { TheEventList },
        data: () => ({
            education: null,
        }),
        methods: {
            async fetchData() {
                await get('education').then(response => {
                    this.education = response.data.education;
                });
            },
        },
        mounted() {
            this.fetchData();
        },
        metaInfo() {
            return {
                title: `Обучение - ЛУКМАРКЕТ`,
            };
        },
    };
</script>

<style lang="scss">
    @media (max-width: 1200px) {
        .visit-page .request-form-btn {
            font: 700 13px/24px Futuris !important;
        }

        .visit-page .request-form-btn {
            font: 700 16px/24px Futuris !important;
            min-width: 210px !important;
        }
        .visit-page .inner-section-title__link,
        .visit-page .inner-section-title {
            font: 700 12px/24px Futuris !important;
            padding: 0 5px !important;
        }
        .visit-page .default-text-decoration {
            font: 15px/30px Futuris !important;
        }
    }

    .visit-page {
        .page-title {
            font: 700 18px/24px Futuris;
            color: #212529;
            padding-top: 40px;
        }

        .close-button {
            cursor: pointer;
            opacity: 1;
            transition: color 0.3s ease-in-out;
            padding: 0 10px;
        }

        .close-button:hover {
            color: #d2233c;
        }

        .brand-color {
            color: #d2233c;
        }

        .col-xl-4.col-lg-4.col-md-4.education {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 0;
        }

        .font-size-1rem {
            font-size: 1rem;
        }

        .white-space-nowrap {
            white-space: nowrap;
        }

        .g-horisontal-spacer {
            min-height: 30px;
        }

        .g-resize {
            display: block;
            width: 100%;
            height: auto !important;
        }

        .img-bottom-spacer {
            margin-bottom: 20px;
        }

        .default-text-decoration {
            font: 21px/30px Futuris;
            color: #4a4a4a;
            text-align: justify;
        }

        .default-text-decoration strong {
            font: 21px/30px Futuris;
        }

        .default-text-decoration p {
            padding: 10px 0 20px;
        }

        .g-horisontal-spacer {
            min-height: 30px;
        }

        .default-text-decoration--ileft-right {
            display: block;
        }

        .default-text-decoration--out-pad {
            display: block;
        }

        .default-text-decoration--two-col {
            column-count: 2;
            column-gap: 30px;
            text-align: left;
        }

        .g-horisontal-line {
            display: block;
            border-bottom: solid #dfe1e3 1px;
            margin-top: 56px;
            margin-bottom: 40px;
        }

        .inner-section-title__link,
        .inner-section-title {
            display: block;
            text-transform: uppercase;
            color: #212529;
            font: 700 16px/24px Futuris;
            text-decoration: none !important;
        }

        .perm-section-title {
            margin-bottom: 2rem;
        }

        .default-link {
            display: inline-block;
            color: #d2233c;
            text-decoration: none !important;
        }

        .container-center {
            display: block;
            text-align: center;
        }

        .request-form-btn {
            text-align: center;
            display: inline-block;
            background: #d2233c;
            border-radius: 4px;
            color: #fff;
            text-transform: uppercase;
            font: 700 16px/24px Futuris;
            margin-top: 20px;
            padding: 10px;
            text-decoration: none;

            @media (max-width: 992px) {
                margin: 20px 0 40px 0;
            }

            @media (min-width: 1100px) {
                min-width: 275px;
            }
        }

        .request-form-btn:hover {
            color: #fff;
            text-decoration: none;
        }

        @media (max-width: 768px) {
            .default-text-decoration--two-col {
                column-count: 1;
                column-gap: 0;
            }
        }
    }
</style>
